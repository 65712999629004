import * as React from "react";

import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import ProfessionalRecruitmentComponent from "../components/use_case/ProfessionalRecruitment";
import { useTranslation } from 'react-i18next';

const ProfessionalRecruitment = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={`${t('professional_recruitment')}`}
        description="Identifiez et recrutez les meilleurs talents grâce à notre suite d’outils sophistiqués."
      />
      <Layout>
        <ProfessionalRecruitmentComponent />
      </Layout>
    </>
  );
};

export default ProfessionalRecruitment;
