import React from "react";

import Ready from "../ready/ready";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Exam from "../exam/exam";
import Latest from "../latest/latest";
// import { Link } from "gatsby"
import UseCaseTopBanner from "../top_banner/product_top_banner";
import Charts from "../charts/charts";
import Logos from "../logos-section/logos";
import { useTranslation } from 'react-i18next';

const ProfessionalRecruitment = () => {
  const { t } = useTranslation();
  const exams = [
    {
      title: `${t('dont_limit_yourself_in_your_choices')}`,
      description: `${t('meet_the_best_candidates_and_evaluate')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/professional-recruitment_exams_1.png",
    },
    
    {
      title: `${t('be_flexible')}`,
      description: `${t('forget_recruiting_sessions')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/flexible 2.png",
    },
    {
      title: `${t('avoid_unpleasant_surprises')}`,
      description: `${t('make_sure_that_no_applicant_cheats')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/AI_exams_2.png",
    },
    {
      title: `${t('a_5_star_recruiting_experience')}`,
      description: `${t('24_support_and_calming_assessment')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg",
    },
  ];

  const LatestData = {
    title1: "Découvrez notre ",
    title2: "dernière publication",
    description: `${t('why_is_a_good_candidate_experience')}`,
    btnText: `${t('to_consult')}`,
  };

  const ChartData={
    title:["Cours Legendre utilise Mereos","pour recruter ses intervenants"],
    contexte:`${t('to_offer_quality_teaching')}`,
    Résultats:[
      {
        heading:`4 ${t('days')}`,
        desc:`${t('to_recruit_a_candidate_compared')}`
      },
      {
        heading:"85%",
        desc:`${t('time_saving_for_recruiters')}`
      },
      {
        heading:"4,5",
        desc:`${t('average_score_given_by_candidates')}`
      }
    ],
    points:[
      `${t('monitoring')}`,
      `${t('flexibility')}`,
      `${t('speed')}`,
      "Engagement"
    ]
  };

  // const breakpoints = useBreakpoint();
  return (
    <div className={`conatiner font-sans`}>
      <UseCaseTopBanner
        pageTitle={`${t('professional_recruitment')}`}
        title={t('stand_out_in_competitive_world')}
        description={t('with_mereos_select_your_candidates')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/professional-recruitment.svg"
        rightArrowImageSrc="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_right_white_icon.svg"
        leftButton={{
          text: `${t("free_trials")}`,
          href: "/free-trial",
        }}
        rightButton={{
          text: `Demo >`,
          href: "/demo",
        }}
        bannerType="useCase"
      />

      {/* logos  */}
      <Logos />

      <div className={`mx-auto w-11/12 lg:w-5/6 `}>
        <div className="my-20">
          <Exam Data={exams} title={t('why_is_mereos_right_for_you')} />
        </div>
      </div>

      <div className={`mx-auto w-11/12 lg:w-5/6`}>
        {/* Latest from Source */}
        <Latest data={LatestData}/>

        {/* Charts */}
        <Charts ChartData={ChartData}/>
      </div>

      {/* Ready */}
      <div className="flex flex-row mt-10 mb-20">
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-end"
        />
        <div className="mt-10 mx-auto w-11/12 lg:w-5/6">
          <Ready />
        </div>

        <img
          alt="left star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-start"
        />
      </div>
    </div>
  );
};

export default ProfessionalRecruitment;
